<template>
  <div>
    <div class="h-handle-bg">
      <div class="h-handle-button">
        <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
        <div class="h-b"><el-button type="primary" size="small" @click="addUser" v-right-code="'System_Right_SysMenu_AddEdit'"
           >新增</el-button> </div>
        <div class="h-b" v-if="dataSource.Id == null"><el-button type="text" size="small" @click="saveUser"  v-right-code="'SysMenu:Edit'"
           >保存</el-button> </div>
        <div class="h-b" v-if="dataSource.Id != null"><el-button type="text" size="small" @click="saveUser"  v-right-code="'SysMenu:Edit'"
           >保存</el-button> </div>
      </div>
    </div>
    <div class="form-list">
      <el-form ref="_userInfoForm" :model="dataSource" :rules="userCheckRule">
        <!-- <el-form-item>
          <el-col :span="4" class="form-title"><span style="color:red;">*</span>权限编码：</el-col>
          <el-col :span="8">
            <el-form-item prop="PermCode">
              <el-input v-model.trim="dataSource.PermCode" :maxlength="30" :minlength="4" :disabled="true"
                :readonly="isDisabled" placeholder="请输入权限编码"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item> -->
 
        <el-form-item>
          <el-col :span="4" class="form-title"><span style="color:red;">*</span>权限名称：</el-col>
          <el-col :span="8">
            <el-form-item prop="PermName">
              <el-input v-model.trim="dataSource.PermName" :maxlength="50" placeholder="请输入权限名称"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" class="form-title">权限功能：</el-col>
          <el-col :span="8">
            <el-form-item prop="PermFiledNames">
              <el-select v-model="dataSource.PermFiledNames" :maxlength="200" filterable :multiple=true placeholder="请选择" @change="permFiledNamesChange()">
                    <el-option v-for="item in sysMethodList" 
                      :key="item.MethodUrl" 
                      :label="item.MethodUrl" 
                      :value="item.MethodUrl">
                    </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="form-title"><span style="color:red;">*</span>权限编码：</el-col>
          <el-col :span="7">
            <el-form-item prop="PermFiledAlias">
              <el-input v-model.trim="dataSource.PermFiledAlias" :maxlength="50" placeholder="权限编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-form-item>
                <el-button type="text" @click="copyText">复制</el-button>
            </el-form-item>
          </el-col>  
        </el-form-item>
        <el-form-item>
          <el-col :span="4" class="form-title"><span style="color:red;">*</span>业务类型：</el-col>
          <el-col :span="8">
            <el-form-item prop="SysBizType">
                <el-select v-model="dataSource.SysBizType" :maxlength="200" >
                    <el-option v-for="item in sysBizTypeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="form-title">是否记录日志：</el-col>
          <el-col :span="8">
           <el-form-item prop="IsRequireLog">
               <el-switch v-model.trim="dataSource.IsRequireLog" active-color="#13ce66" inactive-color="#ff4949">
           	</el-switch>
           </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" class="form-title"><span style="color:red;">*</span>序号：</el-col>
          <el-col :span="8">
            <el-form-item prop="PermSort">
                <el-input v-model.trim="dataSource.PermSort" :maxlength="30" :minlength="4"></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="4" class="form-title">是否承运商：</el-col>
          <el-col :span="8">
           <el-form-item prop="CarrierRight">
               <el-switch v-model.trim="dataSource.CarrierRight" active-color="#13ce66" inactive-color="#ff4949">
           	</el-switch>
           </el-form-item>
          </el-col>-->
        </el-form-item>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" class="form-title"><span style="color:red;"></span>权限描述：</el-col>
          <el-col :span="20">
            <el-form-item prop="PermDesc">
             <el-input
               type="textarea"
               :rows="3"
               placeholder="请输入内容"
               v-model="dataSource.PermDesc">
             </el-input>

            </el-form-item>
          </el-col>
 

        </el-form-item>
      </el-form>
    </div>

    <el-tabs type="border-card">
      <el-tab-pane label="所属菜单">
        <div class="form-padding">
          <el-tree :data="dataSource.treeList" ref="editTree" show-checkbox node-key="SysMenuId" :props="defaultProps"
            :check-strictly="true" :default-checked-keys="[dataSource.SysMenuId]" default-expand-all>
          </el-tree>

        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    var checkUsername = function (rule, value, callback) {
      if (!value) return callback(new Error('登录账号不能为空'));
      callback();
    };
    var realname = function (rule, value, callback) {
      if (!value) return callback(new Error('姓名不能为空'));
      callback();
    };
    var nickname = function (rule, value, callback) {
      if (!value) return callback(new Error('昵称不能为空'));
      callback();
    };
    var checkPassword = (rule, value, callback) => {
      if (!this.isDisabled) {
        if (value === '' || !value) callback(new Error('请输入密码'))
        else {
          if (value.length < 8) {
            callback(new Error('密码至少八位或者八位以上'))
          }
          var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
          if (!patrn.exec(value)) callback(new Error('密码必须包含数字，字母，特殊字符'))
          if (this.dataSource.ReUserPassword !== '') {
            this.$refs._userInfoForm.validateField('ReUserPassword');
          }
          callback();
        }
      } else callback();
    };

    var checkRepassword = (rule, value, callback) => {
      if (!this.isDisabled) {
        if (value === '' || !value) {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.dataSource.UserPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      } else callback();
    };

    var checkMobile = (rule, value, callback) => {
      if (void 0 == value || !value || value.length == 0)
        callback();
      else {
        if (!this.Utils.isMobile(value))
          callback(new Error('联系电话无效.'));
        else
          callback();
      }
    };
    return {
      multipleSelection: [],
      reUserPassword: '',
      dataSourceCopy: {},
      loadding: false,
      isDisabled: false,
      UserStatusList: [
        { label: '正常', value: 1 },
        { label: '冻结', value: 3 },
        { label: '注销', value: 4 }
      ],
      userCheckRule: {
        PermName: [{ required: true, message: '请输入权限名称',  trigger: 'blur' }],
        PermFiledName: [{ required: true, message: '请输入权限字段名称',  trigger: 'blur' }],
        SysBizType: [{ required: true, message: '请选择业务类型',  trigger: 'blur' }],
        PermSort: [{ required: true, message: '请输入序号', trigger: 'blur' } ],
        PermFiledAlias: [{ required: true, message: '请输入权限编码', trigger: 'blur' } ],
      },
      pwd: "",
      rePwd: "",
      defaultProps: {
        children: 'Children',
        label: 'MenuName'
      },
      sysBizTypeList: [
          { label: '系统', value: 100 },
          { label: '业务', value: 200 },
          { label: '通用', value: 300 }
      ],
      sysMethodList:[]
    }
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {}
  },
  mounted() {
    this.Event.$on("clearEditUserForm", () => this.resetForm);
    this.initialSysMethodList();
  },
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
        this.isDisabled = this.dataSource.id != null;
      },
      deep: true
    }
  },
  methods: {
    tab2Click: function (tab, event) {
    },
    handleSelectionChange(val) {

      if (val.length > 0)
        val.forEach((item, index) => item.checked = true);
      else
        this.dataSource.roles.forEach((item, index) => item.checked = false);
    },
    addUser() {
      this.Event.$emit("onAddUser");
    },
    resetForm() {
      !this.isPassValidate && this.$refs['_userInfoForm'].resetFields();//清空表单
    },
    //保存
    saveUser() {
      var _this = this;
      var ids = this.$refs.editTree.getCheckedKeys();
      if (ids == null || ids.length <= 0) {
        _this.Utils.messageBox("所属菜单必选.", "error");
        return;
      }
      if(ids.length!=1){
        _this.Utils.messageBox("所属菜单请选择一个.", "error");
        return;
      }
      _this.dataSource.SysMenuId = ids[0];
      _this.$refs["_userInfoForm"].validate((valid) => {
        _this.isPassValidate = valid;
        if (_this.isPassValidate) {
          _this.$ajax.send("omsapi/syspermission/save", "post", _this.dataSource, (data) => {
            _this.dataSource.Id === null && (_this.dataSource.id = data.Result);
            _this.$parent.syncDataSource();
            _this.Event.$emit("reloadMenuPageList");
            _this.Utils.messageBox("保存成功.", "success");
          });
        } else {
          return false;
        }
      });
    },
    initialSysMethodList(){
        var _this = this;
        _this.$ajax.send("omsapi/common/geturl", "get", {}, (data) => {
            _this.sysMethodList=data.Result;
        });
    },
    copyText() {
      var txt = this.dataSource.PermFiledAlias;
      navigator.clipboard.writeText(txt).then(() => {
        this.$message.success('复制成功')
      })
      .catch(() => {
        this.$message.error('复制失败')
      })
    },
    permFiledNamesChange(){
      if(this.dataSource.PermFiledAlias) return;
      var names = this.dataSource.PermFiledNames;
      var nameArr = names[0].split("/");
      if(nameArr.length>0){
        if(!nameArr[0]) nameArr.splice(0,1);
        var newAttr = [];
        for (let index = 0; index < nameArr.length; index++) {
          newAttr.push(this.Utils.strFirstUpper(nameArr[index]));
        }
        this.dataSource.PermFiledAlias = newAttr.join(":");
      }
    }
  }
}

</script>

<style></style>